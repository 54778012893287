// Generated by Framer (1249b65)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["wiBIAwS_n", "dqNhJdKUV", "nCXN993eO"];

const variantClassNames = {dqNhJdKUV: "framer-v-96uvg9", nCXN993eO: "framer-v-6cnv1k", wiBIAwS_n: "framer-v-1n6dhib"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Desktop: "wiBIAwS_n", Phone: "nCXN993eO", Tablet: "dqNhJdKUV"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "wiBIAwS_n"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "wiBIAwS_n", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-DKCxr", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1n6dhib", className)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"wiBIAwS_n"} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} {...addPropertyOverrides({dqNhJdKUV: {"data-framer-name": "Tablet"}, nCXN993eO: {"data-framer-name": "Phone"}}, baseVariant, gestureVariant)}><motion.div background={{alt: "", fit: "fill"}} className={"framer-muf6gd"} data-framer-name={"Image"} layoutDependency={layoutDependency} layoutId={"tBJvZPwkd"}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-DKCxr [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-DKCxr .framer-14lbm61 { display: block; }", ".framer-DKCxr .framer-1n6dhib { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: 776px; justify-content: flex-start; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 1200px; }", ".framer-DKCxr .framer-muf6gd { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: 91px; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 1200px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-DKCxr .framer-1n6dhib, .framer-DKCxr .framer-muf6gd { gap: 0px; } .framer-DKCxr .framer-1n6dhib > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-DKCxr .framer-1n6dhib > :first-child { margin-top: 0px; } .framer-DKCxr .framer-1n6dhib > :last-child { margin-bottom: 0px; } .framer-DKCxr .framer-muf6gd > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-DKCxr .framer-muf6gd > :first-child { margin-left: 0px; } .framer-DKCxr .framer-muf6gd > :last-child { margin-right: 0px; } }", ".framer-DKCxr.framer-v-96uvg9 .framer-1n6dhib { width: 810px; }", ".framer-DKCxr.framer-v-6cnv1k .framer-1n6dhib { width: 390px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 776
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"dqNhJdKUV":{"layout":["fixed","fixed"]},"nCXN993eO":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const FramerAXVokfMO8: React.ComponentType<Props> = withCSS(Component, css, "framer-DKCxr") as typeof Component;
export default FramerAXVokfMO8;

FramerAXVokfMO8.displayName = "Hero Image";

FramerAXVokfMO8.defaultProps = {height: 776, width: 1200};

addPropertyControls(FramerAXVokfMO8, {variant: {options: ["wiBIAwS_n", "dqNhJdKUV", "nCXN993eO"], optionTitles: ["Desktop", "Tablet", "Phone"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerAXVokfMO8, [])